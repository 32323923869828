@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  min-width: 0;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  @extend %text-body-small;
}

// Show buttons on mobile devices by default
@media screen and (max-width: $mobileScreen) {
  .wrapper {
    opacity: 1;
  }
}

// Parent hover selector will be added to the LineChart component
.parentHover {
  opacity: 1;
}

.button {
  padding: 0;
  height: 22px;

  &Inactive {
    opacity: 0.3;
  }
}
