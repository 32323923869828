@import "src/styles/iconSize";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

// Show buttons always on mobile devices
@media (max-width: calc($mobileScreen - 1px)) {
  .wrapper {
    opacity: 1;
  }
}

// Parent hover selector will be added to the WidgetTile component
.parentHover {
  opacity: 1;
}

.locked {
  pointer-events: none;
  filter: blur(2px);
}

.button {
  position: relative;
  padding: 0;
  border-radius: 4px;
  height: auto;

  svg {
    @include icon-size(24px);
  }
}

.widgetMenu {
  // Ensure the menu dropdown is always visible when opened
  &:global(.open) {
    opacity: 1;
  }
}
