@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/slideAnimation";
@import "src/styles/responsiveContainer";

/* Custom scrollbar styling */
@mixin custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* For Firefox */
  
  /* For Webkit browsers like Chrome/Safari */
  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--container-max-width, 100%);
  position: relative;
  @include custom-scrollbar;
  margin: 0 auto;
  overflow-x: hidden;
  gap: 16px;

  &WithMargin {
    margin-bottom: 64px;
  }
}

.heading {
  @extend %text-heading-large;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  padding: 40px 0;
  gap: 16px;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: min(var(--responsive-container-width), var(--container-max-width, 100%));
  width: 100%;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 8px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;
}

.submitButton {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  gap: 8px;
}

.cancelButton {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  gap: 8px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-background-light);
  filter: drop-shadow(5px -4px 30px rgba(0, 0, 0, 0.06));
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 20;
  box-sizing: border-box;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px var(--content-padding);
    height: 82px;
    width: 100%;
    max-width: min(var(--responsive-container-width, 58rem), var(--container-max-width, 100%));
    margin: 0 auto;
    box-sizing: border-box;
    gap: 16px;
  }
}

.nextStep {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 8px;

  & > button > svg {
    @include icon-size(15px);
  }

  & > span:nth-of-type(1) {
    font-weight: 500;
    text-transform: uppercase;
    padding: 4px 16px;
    color: var(--color-font-dark);
    background-color: var(--color-button-background-secondary);
    border-radius: 20px;
    text-wrap: nowrap;

    @extend %text-body-small;
  }

  & > span:nth-of-type(2) {
    color: var(--color-font-disabled);
    text-wrap: nowrap;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;

    @extend %text-heading-medium;
  }
}

.slideOutLeft {
  @extend %slide-out-left;
}

.slideInLeft {
  @extend %slide-in-left;
}

.slideInRight {
  @extend %slide-in-right;
}

.slideOutRight {
  @extend %slide-out-right;
}

.slideOutBottom {
  @extend %slide-out-bottom;
}

.slideInBottom {
  @extend %slide-in-bottom;
}

@media screen and (max-width: $mobileScreen) {
  .content {
    padding: 24px 0;
  }

  .footer > div {
    height: 62px;
    padding: 16px var(--content-padding, 8px);
  }

  .nextStep {
    & > button > svg {
      @include icon-size(12px);
    }

    & > span:nth-of-type(1) {
      font-size: 10px;
    }

    & > span:nth-of-type(2) {
      font-size: 14px;
    }
  }

  .submitButton,
  .cancelButton {
    height: 32px;
    padding: 4px 16px;
    font-size: 16px;
    line-height: 20px;

    & > svg {
      display: none;
    }
  }
}
