@import "src/styles/textStyles";
@import "../../styles";
@import "src/styles/iconSize";
@import "src/styles/screenSizes";

.generateTrackerLink,
.generateButton {
  display: none;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}

.logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 0;
  aspect-ratio: auto;
  max-width: 100%;
  max-height: 100%;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
  max-width: 44rem;
  margin-left: auto;
  margin-right: auto;
  min-height: 36px;
}

.heading {
  line-height: 1.2;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
  min-width: max-content;
}

.subTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.subHeading {
  color: var(--color-font-secondary);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  @extend %text-body-medium;
}

.configurations {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 24px;
  width: calc(100% - 32px);
  max-width: min(calc(var(--query-container-width, 44rem) - 32px), calc(var(--container-max-width, 100%) - 32px));
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  
  /* Remove the equal width distribution */
  & > div {
    box-sizing: border-box;
    position: relative;
  }
  
  /* Fixed width for both dropdowns */
  & > div:first-child,
  & > div:last-child {
    width: 220px; /* Fixed width for both dropdowns */
    flex-shrink: 0;
  }
}

.content {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: min(var(--query-container-width, 44rem), var(--container-max-width, 100%));
  width: 100%;
  margin: 0 auto;
  position: relative;
  flex: 1 0 auto;
  min-height: 300px;
  justify-content: center;
  margin-bottom: 70px;
  box-sizing: border-box;
  overflow: visible;
}

/* Adjust the section containing the logo and title */
.section:first-of-type {
  gap: 16px;
}

.section {
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0;
}

/* Add specific styling for the textarea section */
.section:nth-child(3) {
  margin-top: 0;
  margin-bottom: 0;
}

.textareaWrapper {
  flex-wrap: nowrap;
  background-color: var(--color-background-light);
  border: none;
  border-radius: 30px; /* Match the Agent component's border-radius */
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 56px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-top: 0;
  width: calc(100% - 32px);
  max-width: min(calc(var(--query-container-width, 44rem) - 32px), calc(var(--container-max-width, 100%) - 32px));
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  
  &:focus-within {
    outline: 1px solid var(--color-border-light);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  }
}

.textarea {
  background: transparent !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: normal !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  resize: none !important;
  box-shadow: none !important;
  outline: none !important;
  height: 56px !important;
  display: flex !important;
  align-items: center !important;
  
  &::placeholder {
    color: var(--color-font-disabled);
    vertical-align: middle;
  }
  
  &:focus {
    outline: none;
  }
}

/* Target the actual textarea element */
textarea.textarea {
  padding: 18px 0 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  width: 100%;
  max-width: var(--container-max-width, 100%);
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Footer container with fixed height */
.footerContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px; /* Fixed height for the footer */
  pointer-events: none; /* Allow clicks to pass through when footer is hidden */
  z-index: 20;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  filter: drop-shadow(5px -4px 30px rgba(0, 0, 0, .06));
  z-index: 20;
  height: 70px; /* Fixed height matching the container */
  pointer-events: auto; /* Re-enable pointer events for the actual footer */
  
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: min(var(--query-container-width, 44rem), var(--container-max-width, 100%));
    margin: 0 auto;
    width: 100%;
    height: 100%; /* Make the inner div take full height */
    padding: 0 var(--content-padding, 16px); /* Use CSS variable for padding */
  }
}

.submitButton {
  font-size: 16px;
  padding: 8px 24px;
  height: 40px;
  line-height: 1.2;
  border-radius: 20px;
  min-width: 120px;
}

.cancelButton {
  font-size: 16px;
  padding: 8px 24px;
  height: 40px;
  line-height: 1.2;
  border-radius: 20px;
  min-width: 100px;
}

.nextStep {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Modify animation classes */
.slideInBottom {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.slideOutBottom {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

@media screen and (max-width: $tabletScreen) {
  .heading {
    font-size: 28px; /* Keep consistent font size */
    line-height: 40px;
  }
  
  .content {
    max-width: 90%;
  }
  
  .textareaWrapper,
  .configurations {
    max-width: 100%;
  }
  
  .wrapper {
    padding-top: 0; /* Remove the top padding as we're using justify-content: center */
  }
  
  .generateTrackerLink {
    top: 15px;
    right: 15px;
    position: absolute;
    z-index: 100;
  }
  
  .generateButton {
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    margin: 15px;
  }
  
  .configurations {
    max-width: 100%;
    flex-direction: row; /* Keep side by side on tablet */
    align-items: stretch;
    gap: 16px; /* Slightly reduced gap on tablet */
    
    & > div:first-child,
    & > div:last-child {
      width: 200px; /* Slightly smaller fixed width on tablet */
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .heading {
    font-size: 28px; /* Keep consistent font size */
    line-height: 40px;
  }
  
  .content {
    max-width: 100%;
    padding: 1.5rem 0;
    margin-bottom: 60px; /* Adjust margin for smaller footer */
  }
  
  .textareaWrapper {
    width: calc(100% - 16px); /* Adjust width for mobile */
    max-width: 100%;
  }
  
  .configurations {
    flex-direction: row; /* Changed from column to row for side-by-side layout */
    align-items: stretch;
    gap: 8px; /* Reduced gap for mobile */
    width: calc(100% - 16px);
    max-width: 100%;
    
    /* Set each dropdown to take up half the width */
    & > div:first-child,
    & > div:last-child {
      width: calc(50% - 4px); /* 50% width minus half the gap */
      max-width: 50%;
      min-width: 0;
      margin: 0;
      
      /* Adjust dropdown styling for mobile */
      :global(.rs-picker-toggle-placeholder),
      :global(.rs-picker-toggle-value) {
        font-size: 14px; /* Smaller font size for mobile */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      /* Adjust error message positioning for side-by-side layout */
      :global(.rs-picker-error) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-size: 12px; /* Smaller font size for error messages */
      }
    }
  }
  
  .wrapper {
    padding-top: 0; /* Remove the top padding as we're using justify-content: center */
  }
  
  .generateTrackerLink {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 100;
  }
  
  .generateButton {
    padding: 0 12px;
    height: 36px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    margin: 10px;
    
    span {
      font-size: 13px;
    }
    
    /* Add a compact version for very small screens */
    @media screen and (max-width: 360px) {
      padding: 0 10px;
      
      span {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  .footerContainer {
    height: 60px; /* Smaller footer height for mobile */
  }
  
  .footer {
    height: 60px; /* Smaller footer height for mobile */
    
    & > div {
      padding: 0 8px; /* Smaller padding for mobile */
    }
  }
  
  .nextStep {
    font-size: 14px; /* Smaller font size for mobile */
  }
  
  .submitButton,
  .cancelButton {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 6px 16px; /* Smaller padding for mobile */
    min-width: 100px; /* Smaller min-width for mobile */
  }
  
  /* Target the specific wrapper class that's causing the scrollbar */
  :global(.CreateTrackers_wrapper_UB1L) {
    overflow: hidden !important;
    height: auto !important;
  }
  
  /* Ensure the scroll container doesn't create unnecessary space */
  :global([class*="CreateTrackers_wrapper_"]) {
    overflow: hidden !important;
    height: auto !important;
  }
}

