@import "../../styles";
@import "src/styles/screenSizes";
@import "src/styles/textStyles";

.wrapper {
  width: 100%;
  max-width: var(--container-max-width, 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  position: relative;
  overflow: visible;
  margin: 0 auto;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7rem;
  padding-top: 2.2rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.generateTrackerLink {
  position: relative;
  display: flex;
  align-items: center;
}

.generateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 36px;
  border-radius: 20px;
  background: white;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  gap: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
  
  span {
    color: var(--color-primary);
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
  }

  .chatAiIcon {
    display: block;
    fill: currentColor;
    stroke-width: 0;
    width: 15px;
    height: 15px;
    color: var(--color-primary);
  }
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    background-color: #f8f8f8;
    text-decoration: none;
  }
}

.slideInBottom {
  @extend %slide-in-bottom;
}

.slideOutBottom {
  @extend %slide-out-bottom;
}

.slideInTop {
  @extend %slide-in-top;
}

.slideOutTop {
  @extend %slide-out-top;
}

@media screen and (max-width: $tabletScreen) {
  .wrapper {
    padding: 0;
  }

  .headerContainer {
    width: 100%;
  }

  .generateButton {
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  }
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 0;
  }

  .headerContainer {
    width: 100%;
    padding: 1.6rem;
  }

  .generateButton {
    padding: 0 12px;
    height: 36px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    
    span {
      font-size: 13px;
    }
    
    @media screen and (max-width: 360px) {
      padding: 0 10px;
      
      span {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
