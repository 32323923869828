@import 'src/styles/screenSizes';
@import 'src/styles/textStyles';

.lineChart {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;

  gap: 15px;
}

.head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  gap: 15px;
}

.chartWrapper {
  display: flex;
  flex: 1;
}

.chartWrapperOuter {
  display: flex;
  flex-direction: column;
}

.chartWrapperInner {
  position: relative;
  display: flex;
  flex: 1;
}

.chart {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
