@import "../../styles";
@import "src/styles/screenSizes";

.content {
  padding: 24px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: min(var(--responsive-container-width, 58rem), var(--container-max-width, 100%));
  width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  max-width: var(--container-max-width, 100%);
  overflow-y: auto;
  overflow-x: hidden;
  @include custom-scrollbar;
  margin: 0 auto;
}

.wrapperWithMargin {
  padding-bottom: 0;
}

.footerContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  pointer-events: none;
  z-index: 20;
}

.submitButton {
  font-size: 16px;
  padding: 8px 24px;
  height: 40px;
  line-height: 1.2;
  border-radius: 20px;
  min-width: 120px;
}

.cancelButton {
  font-size: 16px;
  padding: 8px 24px;
  height: 40px;
  line-height: 1.2;
  border-radius: 20px;
  min-width: 100px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  filter: drop-shadow(5px -4px 30px rgba(0, 0, 0, .06));
  z-index: 20;
  height: 70px;
  pointer-events: auto;
  
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: min(var(--responsive-container-width, 58rem), var(--container-max-width, 100%));
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 var(--content-padding);
  }
}

.slideInBottom {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.slideOutBottom {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nextStep {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: $tabletScreen) {
  .content {
    padding: 16px 0 40px;
    max-width: 90%;
  }
}

@media screen and (max-width: $mobileScreen) {
  .submitButton {
    padding: 6px 20px;
    height: 36px;
    min-width: 110px;
  }
  
  .cancelButton {
    padding: 6px 20px;
    height: 36px;
    min-width: 90px;
  }
  
  .content {
    padding: 16px 0 40px;
    max-width: 100%;
  }
  
  .footer {
    height: 60px;
    
    & > div {
      padding: 0 var(--content-padding, 8px);
    }
  }
  
  .footerContainer {
    height: 60px;
  }
}
