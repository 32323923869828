:root {
  --responsive-container-width: 58rem;
  --query-container-width: 44rem;
  --content-padding: 16px;
  --container-max-width: 100%;
}

@media screen and (max-width: 768px) {
  :root {
    --content-padding: 12px;
    --container-max-width: 95%;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --content-padding: 8px;
    --container-max-width: 90%;
  }
}

/* Global overflow handling */
html, body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply box-sizing to all elements */
*, *:before, *:after {
  box-sizing: inherit;
}

/* Container for the red lines in the UI */
.container-with-lines {
  max-width: var(--container-max-width);
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}

%responsive-container {
  width: 100%;
  max-width: min(var(--responsive-container-width), var(--container-max-width));
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

%query-container {
  width: 100%;
  max-width: min(var(--query-container-width), var(--container-max-width));
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}
