@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  gap: 15px;
}

.content {
  overflow: auto;

  * {
    margin: 0;
    white-space: initial;

    @extend %text-body-large;
  }

  a {
    text-decoration: underline !important;
  }

  h1,
  h1 * {
    margin-bottom: 10px;

    @extend %text-heading-extra-large;
  }

  h2,
  h2 * {
    margin-bottom: 10px;

    @extend %text-heading-large;
  }

  h3,
  h3 * {
    margin-bottom: 10px;

    @extend %text-heading-medium;
  }

  h4,
  h4 * {
    margin-bottom: 10px;

    @extend %text-heading-small;
  }

  h5,
  h5 * {
    margin-bottom: 10px;

    @extend %text-heading-extra-small;
  }

  h6,
  h6 * {
    margin-bottom: 10px;

    @extend %text-heading-extra-small;
  }

  strong {
    font-weight: 500;
  }

  ol,
  ul {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 25px;
    margin: 10px 0;
    list-style: initial;

    gap: 10px;
  }

  li,
  li * {
    @extend %text-body-large;
  }

  p {
    &:not(:first-child):not(:last-child) {
      margin: 20px 0;
    }

    &:first-child:not(:last-child) {
      margin: 0 0 20px;
    }

    &:last-child:not(:first-child) {
      margin: 20px 0 0;
    }
  }

  pre {
    padding: 10px;
    background-color: var(--color-white-secondary-light);
    border-radius: 5px;
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;

    td {
      padding: 8px;
      border: 1px solid var(--color-border-light);
    }

    th {
      font-weight: 500;
      padding: 8px;
      border: 1px solid var(--color-border-light);
    }
  }
}
