.wrapper {
  display: flex;
  flex-direction: column;
  height: 300px;

  gap: 8px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.4);
  border-radius: 8px;
}

.loaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.loaderText {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}
