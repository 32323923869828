@import "src/styles/iconSize";
@import "src/styles/screenSizes";
@import "src/styles/selectStyles";

// Main wrapper styles
.agentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-secondary-light);
  
  /* Responsive width for desktop */
  @media (min-width: calc($mobileScreen)) {
    width: 100%;
    max-width: 100%;
    
    &[data-collapsed="true"] {
      width: 40px;
      max-width: 40px;
      justify-content: center; /* Center content vertically */
    }
  }
  
  /* Hide completely on mobile when collapsed */
  @media screen and (max-width: $mobileScreen) {
    &[data-collapsed="true"] {
      display: none;
    }
  }

  &.reset-agent {
    & > :last-child {
      opacity: 0.99; // Force redraw without visible flicker
    }
  }
}

// Collapsed view styles
.collapsedWrapper {
  padding: 0; /* Removed padding to allow better positioning control */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changed to flex-start to allow specific button positioning */
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-secondary-light);
  position: relative; /* Added for absolute positioning of children */
  
  /* Ensure visibility on desktop */
  @media (min-width: calc($mobileScreen)) {
    width: 40px;
    padding: 0;
    align-items: center;
  }
  
  /* Mobile styles */
  @media screen and (max-width: $mobileScreen) {
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }

  svg {
    fill: transparent;
  }
}

.collapsedButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
  
  /* Ensure visibility in collapsed state */
  .agentWrapper[data-collapsed="true"] & {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute; /* Positioned absolutely to control exact placement */
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
  
  /* Mobile styles */
  @media screen and (max-width: $mobileScreen) {
    .agentWrapper[data-collapsed="true"] & {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      position: relative;
    }
  }
}

/* Position the expand button at the same vertical height as the collapse button */
.collapsedButton {
  display: flex;
  justify-content: center;
  
  /* Ensure visibility in collapsed state */
  .agentWrapper[data-collapsed="true"] & {
    display: flex;
    margin: 0;
    
    /* The 'expand chat' button is the second button in collapsed mode */
    &:nth-child(2) {
      position: absolute;
      top: 0.6em; /* Using em units for more precise alignment with the collapse button */
      right: 8px;
    }
    
    /* The 'new chat' button should be hidden, as requested */
    &:nth-child(1) {
      display: none;
    }
  }
  
  /* Mobile styles */
  @media screen and (max-width: $mobileScreen) {
    .agentWrapper[data-collapsed="true"] & {
      display: flex;
      margin-bottom: 0;
      margin-right: 0.5rem;
      position: relative;
      top: auto;
      right: auto;
    }
  }
}

/* Specific classes for the collapsed view buttons */
.collapsedLeftButton {
  margin-right: auto; /* Push to the left */
}

.collapsedRightButton {
  margin-left: auto; /* Push to the right */
}

// Thread list header styles
.threadList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 60px;
  position: relative;
  z-index: 20;
  background-color: var(--color-background-secondary-light);
  padding: 0;
}

.threadListLeftGroup {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0.5rem;
  padding-left: 1rem;
  background-color: var(--color-background-secondary-light);
}

.threadListRightGroup {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0.5rem;
  padding-right: 1rem;
  background-color: var(--color-background-secondary-light);
}

// Button styles for all icons
.iconButton {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  svg {
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
}

// Add thread button
.addThread {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  svg {
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
  
  /* Hide when in collapsed state */
  .agentWrapper[data-collapsed="true"] & {
    display: none;
  }
}

.newThreadButton {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  svg {
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
}

// Close chat button
.closeChat {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  svg {
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
}

// Chat history button
.chatHistory {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  svg {
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
}

.historyButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeChatWrapper {
  /* No margin needed as we're using flex layout */
}

// Dropdown and select styles
.selectWrapper {
  position: relative;
  flex: 0 0 auto;
  min-width: 0;
  z-index: 40;
  margin-right: auto;
}

.select {
  flex: 1;
  width: 100%;
  border: none;
  background: transparent;
  min-width: 0;
  position: relative;
}

.popup {
  position: absolute;
  z-index: 30;
  left: 0;
  padding: 16px;
  max-width: 400px;
  min-width: 250px;
  display: flex;
  border-radius: 20px;
  background-color: var(--color-background-secondary-light);
  flex-direction: column;
  box-shadow: var(--box-shadow-light);
  gap: 10px;
}

.options {
  overflow-y: auto;
  max-height: 200px;
  cursor: pointer;
  margin: 0;
  padding: 0;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  transition: all .2s;
  gap: 5px;
  line-height: 24px;
  overflow: hidden;
  height: 24px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: var(--color-font-secondary);

  &Selected {
    font-weight: 500;
  }

  &:hover > .optionActions {
    display: flex;
  }
}

.optionLabel {
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionActions {
  display: none;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;
  gap: 5px;
}

.noOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  overflow: hidden;
  height: 24px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: var(--color-font-secondary);
}

.input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.button {
  padding: 0;
  width: 100%;
  color: var(--color-red);

  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;

  svg {
    @include icon-size(15px);
  }
}

// Expand/collapse button
.expandCollapseButton {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  svg {
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
  
  position: relative;
  
  .agentWrapper[data-collapsed="true"] & {
    display: flex;
    visibility: visible;
    opacity: 1;
    margin: 0 auto;
  }
  
  @media screen and (max-width: $mobileScreen) {
    .agentWrapper[data-collapsed="true"] & {
      display: flex;
      visibility: visible;
      opacity: 1;
    }
  }
}

// Mobile-specific styles
.mobileHeader {
  display: none;
  
  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

.mobileCloseButton {
  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

[class*="expandCollapseButton"].mobileCloseButton,
[class*="closeChat"].mobileCloseButton {
  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

.agentWrapper[data-expanded="true"] .threadListRightGroup .expandCollapseButton,
.agentWrapper[data-collapsed="true"] .expandCollapseButton {
  @media screen and (max-width: $mobileScreen) {
    position: relative;
    right: auto;
    top: auto;
  }
}

.mobileInlineButton {
  @media screen and (max-width: $mobileScreen) {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    
    svg {
      width: 24px;
      height: 24px;
      fill: transparent;
      stroke: var(--color-text-primary, #333);
    }
  }
}

.mobileFixedExpandButton {
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  
  svg {
    fill: transparent;
    stroke: var(--color-text-primary, #333);
  }
}

.mobileExpandButtonContainer {
  position: fixed;
  top: 70px;
  right: 16px;
  z-index: 999999;
  display: flex;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: none;
  justify-content: flex-end;
  align-items: flex-start;
}

.mobileExpandButton {
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 40px;
  height: 40px;
  background: var(--color-background-secondary-light);
  border: 1px solid var(--color-border-light);
  display: flex;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 0;
  flex: 0 0 auto;
  
  svg {
    fill: transparent;
    stroke: var(--color-text-primary, #333);
    width: 20px;
    height: 20px;
  }
  
  &:hover {
    background: var(--color-background-secondary-extralight);
  }
}

.mobileSideButton {
  display: none;
}

/* Mobile styles */
@media screen and (max-width: $mobileScreen) {
  .popup {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    left: 0;
    right: 0;
    padding: 16px; /* Consistent padding */
    border-radius: 0; /* Remove rounded corners for full-width mobile view */
  }
  
  .threadList {
    padding: 0;
    background-color: var(--color-background-secondary-light);
    justify-content: space-between;
    padding-left: 16px;  
    padding-right: 16px;
  }
  
  .threadListLeftGroup {
    padding: 0.5rem 0; /* Remove horizontal padding, keep vertical */
    background-color: var(--color-background-secondary-light);
    flex: 0 0 auto;
  }
  
  .threadListRightGroup {
    padding-right: 0; /* Remove right padding */
    background-color: var(--color-background-secondary-light);
    margin-left: auto;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }
  
  .agentWrapper {
    padding: 0; /* Ensure no padding on the wrapper */
  }
  
  /* Main content area padding adjustments */
  :global(.agent) {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .addThread,
  .mobileInlineButton,
  .closeChat {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    
    svg {
      width: 24px;
      height: 24px;
      fill: transparent;
      stroke: var(--color-text-primary, #333);
    }
  }
  
  /* Fix for mobile layout - ensure consistent button styling */
  .mobileInlineButton {
    margin-left: 0; /* Reset margin in the context of right group */
  }
  
  /* Additional mobile-specific adjustments for content */
  .selectWrapper, 
  .historyButtonWrapper {
    margin-left: 0;
  }
  
  /* Ensure content is aligned with the header */
  .agentContentWrapper {
    padding: 0 16px;
  }
}

/* Remove any outlines that might appear as blue/purple */
*:focus,
*:focus-visible,
*:focus-within {
  outline: none;
  box-shadow: none;
}

/* Added for proper content alignment */
.agentContentWrapper {
  padding: 0 16px;
}
