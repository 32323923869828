@import "src/styles/iconSize";
@import "src/styles/screenSizes";

.wrapper {
  position: fixed;
  z-index: 25;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  padding: 8px 24px;
  background-color: var(--color-background-light);

  gap: 16px;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  padding: 4px 0;
  max-height: var(--header-height);

  & > picture {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;

    & > img {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: auto;
    }
  }
}

.buttonsSection {
  display: flex;
  align-content: center;
  justify-content: center;

  gap: 15px;
}

.button {
  padding: 0;

  @include icon-size(36px);
}

.addButton {
  height: 100%;
  padding: 5px 10px 5px 15px;
}

.buttonDesktopWrapper {
  display: initial;

  & > div:first-child {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.buttonTabletWrapper {
  display: none;
}

.buttonMobileWrapper {
  display: none;
}

.dropdown {
  top: 40px !important;
}

@media screen and (max-width: $mobileScreen) {
  .buttonDesktopWrapper {
    display: none;
  }

  .buttonTabletWrapper {
    display: initial;
  }

  .wrapper {
    padding: 16px;
  }

  .logo {
    padding: 0;
  }

  .buttonsSection {
    gap: 10px;
  }

  .logo {
    width: auto;
  }
}
