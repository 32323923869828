@import "src/styles/screenSizes";
@import "src/styles/iconSize";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 15px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
}

.button {
  & > svg {
    display: none;
  }
}

@media screen and (max-width: $mobileScreen) {
  .content {
    & > span {
      display: none;
    }
  }

  .button {
    padding: 0;

    & > span {
      display: none;
    }

    & > svg {
      display: inline;
    }

    @include icon-size(35px);
  }
}
