@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";

$containerWidth: MIN(66%, 300px);

.wrapper {
  display: flex;
  align-items: center;
  width: $containerWidth;
  min-width: 0;
  max-width: 100%;
  justify-content: flex-end;

  gap: 15px;
}

.button {
  height: auto;
  padding: 0;
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    flex: 0 0 auto;

    @include icon-size(20px);
  }

  span {
    white-space: nowrap;
  }

  gap: 5px;

  @extend %text-body-medium;
}

.buttonFilled {
  @extend %text-body-medium;
}

.dashboardMenuWrapper {
  margin-right: 8px;
}

@container dashboard-container (max-width: 991px) {
  .wrapper {
    flex: 1 1;
  }

  .button,
  .buttonFilled {
    padding: 10px;

    & > span {
      display: none;
    }

    @include icon-size(40px);
  }

  .buttonDesktopWrapper {
    display: none;
  }
}
