@import "../../styles";
@import "src/styles/screenSizes";
@import "src/styles/responsiveContainer";

.container {
  max-width: var(--container-max-width, 100%);
  margin: 0 auto;
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: $mobileScreen) {
  .container {
    height: auto;
    min-height: 100%;
    overflow: hidden;
  }
}